import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { ConfirmDelete, Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { AccountModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
    active = '';
    identity = '';
    type = '';
    keyword = '';
}

type response = { [key: string]: unknown };

@Component<AdminAccountIndexController>({
    components: {
        SideNav, ConfirmDelete, Lightbox
    }
})
export default class AdminAccountIndexController extends Vue {
    private doubleClick = false;
    private searchForm = new SearchForm();
    private accountList: object = {};
    private num = 0;
    private pageHtml = "";
    private aaid = 0;
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    public created() {
        this.getAccountList();
    }

    private async getAccountList() {
        const item: { [key: string]: string } = {
            page: this.$route.query.page as string,
            active: this.searchForm.active,
            identity: this.searchForm.identity,
            type: this.searchForm.type,
            keyword: this.searchForm.keyword,
            token: this.token,
        }
        const data = await AccountModel.getList(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.accountList = data.list as object;
        this.pageHtml = data.pageHtml as string;
    }

    private delAccount(id: number) {
        this.$emit("openConfirmDelete", true);
        this.aaid = id;
    }

    private async deleteAccount() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item = {
                aaid: this.aaid,
                token: this.token,
            }
            this.errNo = await AccountModel.del(item);
            this.$emit("closeConfirmDelete", false);
            Common.checkToken(this.errNo);
            this.errorMsg = ErrorMessage[this.errNo];
            this.openLB = true;
            this.doubleClick = false;
        }
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.go(0); //reload page
        }
    }

}
